<template>
  <div class="service">
    <section class="banner" :class="{'active': mounted}">
      <div class="parallax-banner" id="scene">
        <div class="banner-img"></div>
      </div>
      <div class="title">
        <div class="underline"></div>
        <div class="title-text"><span>Service</span></div>
      </div>
    </section>

    <section class="flow">
      <div class="container">
        
        <div class="flow-list">
          <div class="flow-title">
            <h2>設計流程</h2>
            <h3>Design Process</h3>
          </div>
          <ul>
            <li>
              <div class="desc">
                <p>初步討論<br/>hello & Meet</p>
              </div>
              <div class="desc-icon">
                <img :src="require('@/assets/images/service/1.png')" />
              </div>
            </li>

            <li>
              <div class="desc">
                <p>現場丈量<br/>Measuring</p>
              </div>
              <div class="desc-icon">
                <img :src="require('@/assets/images/service/2.png')" />
              </div>
            </li>

            <li>
              <div class="desc">
                <p>平面配規劃<br/>Conceptual design</p>
              </div>
              <div class="desc-icon">
                <img :src="require('@/assets/images/service/3.png')" />
              </div>
            </li>

            <li>
              <div class="desc">
                <p>設計委任<br/>Contracting for design</p>
              </div>
              <div class="desc-icon">
                <img :src="require('@/assets/images/service/4.png')" />
              </div>
            </li>

            <li>
              <div class="desc">
                <p>細部設計<br/>Detail design & drawings</p>
              </div>
              <div class="desc-icon">
                <img :src="require('@/assets/images/service/5.png')" />
              </div>
            </li>

            <li>
              <div class="desc">
                <p>工程報價<br/>Construction Bidding & Negotiation</p>
              </div>
              <div class="desc-icon">
                <img :src="require('@/assets/images/service/6.png')" />
              </div>
            </li>

            <li>
              <div class="desc">
                <p>工程實施<br/>Contracting for construction</p>
              </div>
              <div class="desc-icon">
                <img :src="require('@/assets/images/service/7.png')" />
              </div>
            </li>

            <li>
              <div class="desc">
                <p>完工交屋<br/>Completion</p>
              </div>
              <div class="desc-icon">
                <img :src="require('@/assets/images/service/8.png')" />
              </div>
            </li>

          </ul>
        </div>
      </div>
      
    </section>

    <DFCDFooter></DFCDFooter>
  </div>
</template>

<script>
import DFCDFooter from '@/components/DFCDFooter.vue';
import simpleParallax from 'simple-parallax-js';

export default {
  name: 'Service',
  components: {
    DFCDFooter,
  },
  data() {
    return {
      mounted: false,
    }
  },
  props: {
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, 600);
    
    setTimeout(() => {
      const scene = document.getElementById('scene');
      const p = new simpleParallax(scene, {
        orientation: 'down',
        scale: 1.5
      });
      console.log(p);
    }, 500);
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/basic";

  .banner {
    position: relative;
    width: 100%;
    height: 100dvh;
    height: 100vh;
    overflow: hidden;

    .parallax-banner {
      position: relative;
      width: 100%;
      height: 100dvh;
      height: 100vh;
      .banner-img {
        width: 105%;
        height: 100%;
        background-image: url('~@/assets/images/About_01.jpg');
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        transition: opacity 2s ease;
        // transform: translate(0%, 0);
        opacity: 0;
      }
    }

    .title {
      position: absolute;
      bottom: 15%;
      right: 6rem;
      width: 30%;
      display: flex;
      align-items: flex-end;
      column-gap: 1rem;
      @include smaller-than-medium {
        right: 15%;
        left: 0%;
        width: initial;
      }
      .underline {
        flex: 1 1;
        position: relative;
        &:after {
          display: block;
          content: '';
          width: 0;
          transition: all .8s .5s ease;
          border-bottom: solid 2px $color-link-black;
        }
        margin-bottom: .5rem;
      }
      .title-text {
        flex: 0 0 auto;
        overflow: hidden;
        span {
          font-size: 1.5rem;
          display: inline-block;
          transform: translate(0, 100%);
          transition: all .8s 1.3s ease;
        }
      }
    }

    &.active {
      .banner-img {
        // transform: translate(-4.7%, 0);
        opacity: 1;
      }
      .title {
        .underline {
          &:after {
            width: 100%;
          }
        }
        .title-text {
          span {
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .flow {
    position: relative;
    
    .flow-list {
      max-width: 600px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 160px;
      padding-top: 4rem;
      padding-bottom: 4rem;
      .flow-title {
        // position: absolute;
        // bottom: calc(100% + 2rem);
        // right: 55%;
        // text-align: right;
        // color: #fff;
        margin-bottom: 2rem;
        padding: 0 4rem;
        h2 {
          font-size: 1.8rem;
          margin-bottom: 0;
        }
        h3 {
          font-size: 1.2rem;
        }
        @include smaller-than-medium {
          padding: 0;

          position: relative;
          left: 15%;
          h2 {
            font-size: 1.5rem;
          }
          h3 {
            font-size: 1rem;
          }
        }
      }
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          padding: 0;
          margin: 0;
          margin-bottom: 2rem;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row-reverse;
          column-gap: 2rem;
          align-items: center;
          .desc {
            flex: 0 0 50%;
            padding-left: 2rem;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            row-gap: .8rem;
            border-left: solid 4px #D9D9D9;
            margin: 1.5rem 0;
            p {
              margin-bottom: 0;
            }

          }
          .desc-icon {
            flex: 0 0 50%;
            text-align: end;
            padding: 1rem 0;
            img {
              max-width: calc(100% - 6rem);
              
            }
            @include smaller-than-medium {
              text-align: center;
              padding: 0;

              img {
                max-width: 60%;
              }
            }
          }
          &:nth-child(2n) .desc{
            border-left: solid 4px #000;
          }
        }
      }

      @include smaller-than-medium {
        padding-top: 4rem;
        padding-right: 0px;
        max-width: 16rem;

        ul {
          li {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-bottom: 4rem;
            // column-gap: 1rem;

            .desc {
              flex: 0 0 85%;
              p {
                line-height: 2em;
              }
            }
            .desc-icon {
              flex: 0 0 100%;
            }
          }
        }
      }
    }
  }

</style>
