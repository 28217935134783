var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service"},[_c('section',{staticClass:"banner",class:{'active': _vm.mounted}},[_vm._m(0),_vm._m(1)]),_c('section',{staticClass:"flow"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flow-list"},[_vm._m(2),_c('ul',[_c('li',[_vm._m(3),_c('div',{staticClass:"desc-icon"},[_c('img',{attrs:{"src":require('@/assets/images/service/1.png')}})])]),_c('li',[_vm._m(4),_c('div',{staticClass:"desc-icon"},[_c('img',{attrs:{"src":require('@/assets/images/service/2.png')}})])]),_c('li',[_vm._m(5),_c('div',{staticClass:"desc-icon"},[_c('img',{attrs:{"src":require('@/assets/images/service/3.png')}})])]),_c('li',[_vm._m(6),_c('div',{staticClass:"desc-icon"},[_c('img',{attrs:{"src":require('@/assets/images/service/4.png')}})])]),_c('li',[_vm._m(7),_c('div',{staticClass:"desc-icon"},[_c('img',{attrs:{"src":require('@/assets/images/service/5.png')}})])]),_c('li',[_vm._m(8),_c('div',{staticClass:"desc-icon"},[_c('img',{attrs:{"src":require('@/assets/images/service/6.png')}})])]),_c('li',[_vm._m(9),_c('div',{staticClass:"desc-icon"},[_c('img',{attrs:{"src":require('@/assets/images/service/7.png')}})])]),_c('li',[_vm._m(10),_c('div',{staticClass:"desc-icon"},[_c('img',{attrs:{"src":require('@/assets/images/service/8.png')}})])])])])])]),_c('DFCDFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"parallax-banner",attrs:{"id":"scene"}},[_c('div',{staticClass:"banner-img"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"underline"}),_c('div',{staticClass:"title-text"},[_c('span',[_vm._v("Service")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow-title"},[_c('h2',[_vm._v("設計流程")]),_c('h3',[_vm._v("Design Process")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('p',[_vm._v("初步討論"),_c('br'),_vm._v("hello & Meet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('p',[_vm._v("現場丈量"),_c('br'),_vm._v("Measuring")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('p',[_vm._v("平面配規劃"),_c('br'),_vm._v("Conceptual design")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('p',[_vm._v("設計委任"),_c('br'),_vm._v("Contracting for design")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('p',[_vm._v("細部設計"),_c('br'),_vm._v("Detail design & drawings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('p',[_vm._v("工程報價"),_c('br'),_vm._v("Construction Bidding & Negotiation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('p',[_vm._v("工程實施"),_c('br'),_vm._v("Contracting for construction")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('p',[_vm._v("完工交屋"),_c('br'),_vm._v("Completion")])])
}]

export { render, staticRenderFns }